import { Link } from "gatsby"
import React from "react"
import Page from "../../components/page"
import ResponsiveImageGrid from "../../components/responsive-image-grid"
import { themes } from "../../styles/themes"
import paths from "../../utils/paths"
import useScreenSize from "../../utils/useScreenSize"

const backRoomGrid = (labels, isSmallScreen, pagePaths) => [
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: [
      {
        type: "image",
        weight: 100,
        src: "/images/interiors/back-room-blue.jpg",
        alt: labels.interiorsBackRoomBlueAlt,
        imgTitle: labels.interiorsBackRoomBlueImgTitle
      }
    ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    margin: true,
    grid: isSmallScreen
      ? [
          {
            type: "image",
            weight: 57.5,
            src: "/images/interiors/deep-throat-eye.jpg",
            containerClass:
              "img-container interiors-deep-throat-eye-img-container",
            alt: labels.interiorsBackRoomDeepThroatEyeAlt,
            imgTitle: labels.interiorsBackRoomDeepThroatEyeImgTitle
          },
          {
            type: "image",
            weight: 41,
            src: "/images/interiors/deep-throat-side.jpg",
            containerClass:
              "img-container interiors-deep-throat-side-img-container",
            alt: labels.interiorsBackRoomDeepThroatSideAlt,
            imgTitle: labels.interiorsBackRoomDeepThroatSideImgTitle
          }
        ]
      : [
          {
            type: "image",
            weight: 41,
            src: "/images/interiors/deep-throat-side.jpg",
            containerClass:
              "img-container interiors-deep-throat-side-img-container",
            alt: labels.interiorsBackRoomDeepThroatSideAlt,
            imgTitle: labels.interiorsBackRoomDeepThroatSideImgTitle
          },
          {
            type: "image",
            weight: 57.5,
            src: "/images/interiors/deep-throat-eye.jpg",
            containerClass:
              "img-container interiors-deep-throat-eye-img-container",
            alt: labels.interiorsBackRoomDeepThroatEyeAlt,
            imgTitle: labels.interiorsBackRoomDeepThroatEyeImgTitle
          }
        ]
  },
  {
    type: "grid",
    orientation: "horizontal",
    grid: [
      {
        type: "html",
        weight: 100,
        html: (
          <div className="interiors-public-and-business-other-text-container">
            <p>{labels.interiorsOtherText}:</p>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.publicAndBusinessInteriors}
            >
              <p>{labels.interiorsBusinessAndPublicOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.privateInteriors}
            >
              <p>{labels.interiorsPrivateOtherText}</p>
            </Link>
            <Link
              className="interiors-public-and-business-other-link-text"
              to={pagePaths.gameOfLightsInteriors}
            >
              <p>{labels.interiorsGameOfLightsOtherText}</p>
            </Link>
          </div>
        )
      }
    ]
  }
]

export default function BackRoom({ labels, ...props }) {
  const isSmallScreen = useScreenSize()

  return (
    <Page
      {...props}
      labels={labels}
      theme={themes(labels).ipsBlack(false)}
      title={labels.backRoomInteriors}
      description={labels.backRoomInteriorsDescription}
      keywords={labels.backRoomInteriorsKeywords}
      ogUrl={paths(labels).backRoomInteriors}
      ogImage="/images/og-images/back-room-interiors.jpg"
      headerPadding={isSmallScreen}
    >
      <ResponsiveImageGrid
        grid={backRoomGrid(labels, isSmallScreen, paths(labels))}
        margin={false}
      />
    </Page>
  )
}
